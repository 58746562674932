@import url("https://fonts.googleapis.com/css?family=Roboto+Mono:600");

*,
:after,
:before {
  box-sizing: border-box;
}

html, body, div#root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto Mono", Arial, Helvetica, sans-serif;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #005d68;
  transition: 0.1s;
}

a:hover {
  color: #00bbcf;
}

.display-none {
  display: none !important;
}

.small-text {
  font-size: 0.75rem;
}
